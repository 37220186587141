<template>
  <v-navigation-drawer
    v-model="$root.leftMenuDrawer"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    dark
    app
    width="260"
    v-bind="$attrs"
  >
    <v-divider class="mb-1" />
    <v-list>
      <v-list-item
        two-line
        class="px-2"
        link
        @click.prevent="$root.dialogShow = true"
      >
        <v-list-item-avatar>
          <v-img :src="getImage()"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ $root.profile.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ $root.profile.login }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <a-list-main-menu :value="main_menu" :role="$root.profile.role" />

    <template v-slot:append>
      <v-list-item link @click="$root.logout">
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("Log out") }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      main_menu: [
        {
          route: {
            name: "index",
          },
          icon: "mdi-chart-bar",
          title: "Главная",
          name: "Главная",
          auth: 50,
        },
        {
          route: {
            name: "get-card-item",
          },
          icon: "mdi-account-box",
          title: "Получить заявку",
          name: "Получить заявку",
          auth: 50,
        },
        {
          route: {
            name: "event-table",
            params: {
              api: "cardManagerEvent",
              view: "caller_manager_events",
            },
          },
          icon: "mdi-calendar-blank",
          title: "Мои задания",
          name: "Мои задания",
          auth: 50,
        },
        {
          route: {
            name: "index-table",
            params: {
              api: "cardEvent",
              view: "caller_events",
            },
          },
          icon: "mdi-calendar-blank-multiple",
          title: "Все задания",
          name: "Все задания",
          auth: 100,
        },
        {
          route: {
            name: "index-table",
            params: {
              api: "cardActiveManagerRequest",
              view: "caller_manager_request",
            },
          },
          icon: "mdi-credit-card",
          title: "Мои активные",
          name: "Мои активные",
          auth: 50,
        },
        {
          route: {
            name: "index-table",
            params: {
              api: "cardActiveRequest",
              view: "caller_request",
            },
          },
          icon: "mdi-credit-card-multiple",
          title: "Все активные",
          name: "Все активные",
          auth: 100,
        },
        {
          route: {
            name: "card-items",
            params: {
              api: "cardItem",
              view: "caller_item",
            },
          },
          icon: "mdi-account-box-multiple",
          title: "Все заявки",
          name: "Все заявки",
          auth: 100,
        },
        {
          route: {
            name: "call-table",
            params: {
              api: "cardManagerCalls",
              view: "call_log_manager",
            },
          },
          icon: "mdi-phone",
          title: "Мои звонки",
          name: "Мои звонки",
          auth: 50,
        },
        {
          route: {
            name: "call-table",
            params: {
              api: "cardCalls",
              view: "call_log",
            },
          },
          icon: "mdi-phone-settings",
          title: "Все звонки",
          name: "Все звонки",
          auth: 100,
        },
        {
          route: {
            name: "users-table",
            params: {
              api: "users",
              view: "users",
            },
          },
          icon: "mdi-account-supervisor",
          title: "Пользователи",
          name: "Пользователи",
          auth: 1000,
        },
        {
          route: {
            name: "promo",
          },
          icon: "mdi-chart-bar",
          title: "Промокоды",
          name: "Промокоды",
          auth: 1000,
        },
        {
          route: {
            name: "statistic",
          },
          icon: "mdi-chart-bar",
          title: "Статистика",
          name: "Статистика",
          auth: 1000,
        },
        {
          route: {
            name: "config",
          },
          icon: "mdi-cog",
          title: "Настройка",
          name: "Настройка",
          auth: 1000,
        },
      ],
    };
  },
  methods: {
    getImage() {
      let im = this.$store.getters["auth/profile"].image;
      let path;

      if (im) {
        path = this.$root.api.image_download + "/" + im + "?width=50&height=50";
      } else {
        path = "/img/man.png";
      }
      return path;
    },
  },
};
</script>